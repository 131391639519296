
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import { Component, Watch} from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, groupBy } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import DataLoading from '@/lib/mixins/dataLoading';
import { currentDate } from '@/utils/dates';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import scheduleModule from '@/store/modules/scheduleModule';
import workflowModule from '@/store/modules/workflowModule';
import workspaceModule from '@/store/modules/workspaceModule';
import assetsModule from '@/store/modules/assetsModule';
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TasqItemKanban: () => getComponent('tasqs/TasqItemKanban'),
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
	ScheduleModal: () => getComponent('common/ScheduleModal'),
    draggable,
    Multiselect,
  },
})
export default class Kanban extends mixins() {

    // Schedule section
    popupBackground = false
    addToSchedulePopupInnerShowing = false
    addToScheduleIsLoading = false
    addJobChecklistPopup = false

    addToScheduleScheduleSelected = null
    addJobGroupSelected = ''
    checklistTemporaryText = ''

    refreshResponseDataKey = 0
    addJobShowChecklistPopupValues: any[] = []
    addJobResponseData = {}

    addJobIsLoading = false

    // End schedule section

  dataLoading = false
  selectedUsernames: any = []
  typesOptions = [] as any;
   isActionNotPerform = false;
  showFilterCycleTimeMenu = false;
  showRouteMenuCloseBtn = false;
  showFilterRouteMenu = false;
  cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70','80', '90'];
  defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700','800', '900'];
    maxNumCycleMax = 7;
   maxNumDefermentMax = 7;
  showFilterDefermentMenu = false;
  showDefermentMenuCloseBtn = false
  showFilterJobTypeMenu = false;
  showFilterUserMenu = false;
  showTeamMenuCloseBtn = false;
  showFilterTeamMenu =false;
  hideWellDetail = false;
  activeMoveTasqID: any = null;
  showCycleTimeMenuCloseBtn = false;
  showJobTypeMenuCloseBtn = false
  showUserMenuCloseBtn = false;
  hideSignals = false;
  cycleTimeDays: any = null;
  filterType = '';
  showModal = false;
  showScheduleModal = false
  defermentDays: any = null;
  showFilterMenu = false;
  selectedRoutes: any = [];
  selectedTeams: any = []
  cycleTimeGreaterThanFilter: any = null;
  cycleTimeLessThanFilter: any = null;
  unitsGreaterThanFilter: any = null;
  unitsLessThanFilter: any = null;
  draggableData: {
    username: string,
    email: string,
    tasqs: TasqJob[],
    new: TasqJob[],
    inProgress: TasqJob[],
    waitingOn: TasqJob[],
    scheduled: TasqJob[],
    resolved: TasqJob[],
  }[] = [];
  get currentDate() {
    return currentDate();
  }
  nameWithDays(name) {
    return `${name} ${name === '1' ? 'Day' : 'Days'}`;
  }
  nameWithUnits(name) {
    return `${name}`;
  }
  get tasqs(): TasqJob[] {
      var tasqList: any[] = []
      var completedList: any[] = []
      for (var x = 0; x < tasqsListModule.tasqList.length; x++) {
          var tasq_was_schedule = false
          // @ts-ignore
          for (var y = 0; y < workspaceModule.totalItems.length; y++) {
              // @ts-ignore
              if (workspaceModule.totalItems[y].id == tasqsListModule.tasqList[x].id) {
                tasq_was_schedule = true
                  break
              }
          }
          if (!tasq_was_schedule) {
              tasqList.push(tasqsListModule.tasqList[x])
          }
          
      }
      for (var x = 0; x < tasqsListModule.completedTasqList.length; x++) {
          var tasq_was_schedule = false
          // @ts-ignore
          for (var y = 0; y < workspaceModule.totalItems.length; y++) {
              // @ts-ignore
              if (workspaceModule.totalItems[y].id == tasqsListModule.completedTasqList[x].id) {
                tasq_was_schedule = true
                  break
              }
          }
          if (!tasq_was_schedule) {
              completedList.push(tasqsListModule.completedTasqList[x])
          }
          
      }

      // @ts-ignore
    return [...tasqList, ...workspaceModule.totalItems, ...completedList];
  }
  get teams() {
    return workflowModule.teams;
  }
  get activeCycleTimeDays() {
    return tasqsListModule.cycleTimeDays;
  }
  get activeDefermentDays() {
    return tasqsListModule.defermentDays;
  }
  get activeTeams() {
    return tasqsListModule.activeFilterTeam;
  }
  get activeRoutes() {
    return tasqsListModule.activeFilterRoute;
  }
  get kanbanTasq() {
    return tasqsListModule.kanbanSelectedTasq;
  }
  get activeCycleTimeLessThanFilter() {
    return tasqsListModule.cycleTimeLessThanFilter;
  }
   get activeCycleTimeGreaterThanFilter() {
    return tasqsListModule.cycleTimeGreaterThanFilter;
  }
   get activeUnitsLessThanFilter() {
    return tasqsListModule.unitsLessThanFilter;
  }
   get activeUnitsGreaterThanFilter() {
    return tasqsListModule.unitsGreaterThanFilter;
  }
  
  checkMove(event) {
    const { draggedContext } = (event);
    this.activeMoveTasqID = (draggedContext.element.id);
    console.log(event.from.id);
    console.log(event.to.id);
  }
  closeFilterUserMenu() {
    this.showFilterUserMenu = false;
  }
  closeFilterRouteMenu(){
    this.showFilterRouteMenu = false;
  }
  closeFilterCycleTimeMenu() {
    this.showFilterCycleTimeMenu = false;
  }
  closeFilterDefermentMenu() {
    this.showFilterDefermentMenu = false;
  }
  closeFilterTeamMenu() {
    this.showFilterTeamMenu = false;
  }
  closeFilterJobTypeMenu() {
    this.showFilterJobTypeMenu = false;
  }

  isTasqFromScheduledPage(tasqID) {
      for (var x = 0; x < this.tasqs.length; x++) {
          // @ts-ignore
          if (this.tasqs[x].id == tasqID && this.tasqs[x].scheduledJobID != null) {
              return true;
          }
      }
      return false
  }

  getTasqFromScheduledPage(tasqID) {
      for (var x = 0; x < this.tasqs.length; x++) {
          // @ts-ignore
          if (this.tasqs[x].id == tasqID && this.tasqs[x].scheduledJobID != null) {
              return this.tasqs[x];
          }
      }
      return null
  }


  async checkAdd(event) {
    console.log(event.from.id);
    console.log(event.to.id);
    this.sleep(300).then(async () => {
      if (event.to.id.split('-')[1] !== event.from.id.split('-')[1]) {
        this.reasignTasq({ comment: '', person: event.to.id.split('-')[1], id: this.activeMoveTasqID });
        this.$eventBus.$emit('SHOW_ALERT', 'Successfully reassigned!');
      } else if (this.activeMoveTasqID && event.from.id.split('-')[0] === 'waitingOn') {
		//   var scheduledTasq = this.getTasqFromScheduledPage(this.activeMoveTasqID)
		//   if (scheduledTasq != null) {
		// 	var updateStatusVal: any = null
		// 	if (event.to.id.split('-')[0] === 'waitingOn') {
		// 		updateStatusVal = "Waiting On"
		// 	} else if (event.to.id.split('-')[0] === 'scheduled') {
		// 		updateStatusVal = "Scheduled"
		// 	} else if (event.to.id.split('-')[0] === 'new') {
		// 		updateStatusVal = "New"
		// 	} else if (event.to.id.split('-')[0] === 'inprogress') {
		// 		updateStatusVal = "In Progress"
		// 	} else if (event.to.id.split('-')[0] === 'resolved') {
		// 		updateStatusVal = "Done"
		// 	}

		//       await scheduleModule.updateItemStatus({
        //           // @ts-ignore
        //         item_id: scheduledTasq.scheduledJobID,
        //         item_status: updateStatusVal		  
        //       })

		//   }

        await tasqActionsModule.unsnoozeJob(this.activeMoveTasqID);
        this.hideWellDetail = false;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      } else if (this.activeMoveTasqID && event.to.id.split('-')[0] === 'waitingOn') {
        this.hideWellDetail = true;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      // this.$refs.childRef?.prepareAction('reassign');
      } else if (this.activeMoveTasqID && event.to.id.split('-')[0] === 'scheduled') {
          var scheduledTasq = this.getTasqFromScheduledPage(this.activeMoveTasqID)

          if (scheduledTasq == null) {
            this.popupBackground = true
            this.addToSchedulePopupInnerShowing = true
          } else {
			  updateStatusVal = "Scheduled"
		      await scheduleModule.updateItemStatus({
                  // @ts-ignore
                item_id: scheduledTasq.scheduledJobID,
                item_status: updateStatusVal		  
              })	  
		  }



		  this.showModal = true
		  this.showScheduleModal = true
            tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
            // tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
      } else if (this.activeMoveTasqID) {
      // this.hideSignals = true;
        this.hideWellDetail = false;
        tasqsListModule.setActiveTasq(this.activeMoveTasqID || '');
        tasqsListModule.setKanbanSelectedTasq(this.activeMoveTasqID || '');
        this.showModal = true;
      // this.$refs.childRef?.prepareAction('reassign');
      }

      if (event.from.id != event.to.id && this.isTasqFromScheduledPage(this.activeMoveTasqID)) {
          var updateStatusVal: any = null
          if (event.to.id.split('-')[0] === 'waitingOn') {
              updateStatusVal = "Waiting On"
          } else if (event.to.id.split('-')[0] === 'scheduled') {
              updateStatusVal = "Scheduled"
          } else if (event.to.id.split('-')[0] === 'new') {
              updateStatusVal = "New"
          } else if (event.to.id.split('-')[0] === 'inprogress') {
              updateStatusVal = "In Progress"
          } else if (event.to.id.split('-')[0] === 'resolved') {
              updateStatusVal = "Done"
          }
          var scheduledTasq = this.getTasqFromScheduledPage(this.activeMoveTasqID)
          
          //# New, In Progress, Waiting On, Scheduled, Done
        //   if (updateStatusVal && event.from.id.split('-')[0] != 'scheduled') {
			if (updateStatusVal) {
              await scheduleModule.updateItemStatus({
                  // @ts-ignore
                item_id: scheduledTasq.scheduledJobID,
                item_status: updateStatusVal		  
              })
			  if (event.to.id.split('-')[0] != 'waitingOn') {
				  this.showModal = false
				  location.reload()
			  }
              
              
          }
      }
    });
  }
  get activeTasq() {
    return tasqsListModule.activeTasq;
  }
  get activeTasqsCount() {
    return this.tasqs.filter((i) => i.stepOneCompleted && !i.completed).length;
  }
  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }
  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
      color: this.randomBackground(),
    })).sort((a, b) => a.text.localeCompare(b.text));
  }


    get enabledWells() {
        return assetsModule.enabledWells;
    }


  async addJob() {
      this.addToScheduleIsLoading = true
	  // @ts-ignore
        this.addJobIsLoading = true
        var groupSelected = null
        // @ts-ignore
        for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
            // @ts-ignore
            if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
                // @ts-ignore
                groupSelected = workspaceModule.activeTable.Groups[x]
            }
        }

        var columns: any[] = []
        for (const [key, value] of Object.entries(this.addJobResponseData)) {
            // @ts-ignore
            if (value["RequiredOnJobCreation"]) {
                
                // @ts-ignore
                if (value["Response"] == '' || value["Response"] == null || value["Response"] == undefined || value["Response"] == [] || value["Response"] == {}) {
                    console.log("[x] Did not fill out all required fields")

                    this.addToScheduleIsLoading = false
					// @ts-ignore
                    this.addJobIsLoading = false
                    return
                }
            }
            // @ts-ignore
            if (value["ColumnType"] == "STATUS") {
                // @ts-ignore
                value["Response"] = "Scheduled"
            }
            // @ts-ignore
            if (value["ColumnType"] == "WELL") {
                // @ts-ignore
                // @ts-ignore
                if (value["Response"]["value"] != null) {
                    // @ts-ignore
                    value["Response"] = value["Response"]["value"]
                } else {

                }
            }
            columns.push(value)
        }

        await scheduleModule.postNewGroupItem({
            username: accountModule.user.email.toLowerCase(),
            // @ts-ignore
            workspace_id: workspaceModule.activeWorkspace.ID,
            // @ts-ignore
            table_id: workspaceModule.activeTable.ID,
            // @ts-ignore
            group_id: groupSelected.ID,
            columns: JSON.stringify(columns),
            prediction_id: this.activeMoveTasqID
        })
		// @ts-ignore
        this.addJobIsLoading = false
        this.addNewJobHideClicked()
        window.location.reload();
        this.addToScheduleIsLoading = false
    }



    // NEEDED
    addNewJobHideClicked() {
        this.popupBackground = false
		// @ts-ignore
        this.addToSchedulePopupInnerShowing = false
        
    }





  addJobDidEnterChecklistItem(customName, e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
        var newChecklist = {
			// @ts-ignore
            value: this.checklistTemporaryText,
            checked: false
        }
            // Do something
            this.addJobResponseData[customName].Response = [newChecklist].concat(this.addJobResponseData[customName].Response)
			// @ts-ignore
            this.checklistTemporaryText = ''
    }
  }



    addJobRemoveChecklistItem(customName, checklistIndex) {
        this.addJobResponseData[customName].Response.splice(checklistIndex, 1);
        this.refreshResponseDataKey = this.refreshResponseDataKey + 1
    }


  addJobShowChecklistPopup() {
      this.addJobChecklistPopup = true
  }

  addJobHideChecklistPopup() {
      this.addJobChecklistPopup = false
  }


  addJobColumns() {
      if (this.addJobGroupSelected == '') {
          return
      }
      if (workspaceModule.activeTable == null) {
          return
      }
    var groupSelected;
    // @ts-ignore
      for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
          // @ts-ignore
          if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
              // @ts-ignore
              groupSelected = workspaceModule.activeTable.Groups[x]
          }
      }

      var columns = groupSelected.Columns
      return columns

  }




  get scheduleNames() {
      var results: any[] = []
      if (workspaceModule.activeTables == null) {
          return results
      }
      // @ts-ignore
      for (var x = 0; x < workspaceModule.activeTables.length; x++) {
          // @ts-ignore
          results.push({
              value: workspaceModule.activeTables[x].ID,
              text: workspaceModule.activeTables[x].TableTitle,
          })
      }
      return results
  }



  get groupNames() {
      var results = []
      if (workspaceModule.activeTable == null) {
          return results
      }
      // @ts-ignore
      for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
          // @ts-ignore
          results.push(workspaceModule.activeTable.Groups[x].GroupName)
      }
      return results
  }


    // addToScheduleSelectedSchedule = null

    addToScheduleDidSelectSchedule() {
        // @ts-ignore
        workspaceModule.setActiveTable({id: this.addToScheduleScheduleSelected})
    }

    addToScheduleDidSelectGroup() {
        this.addToScheduleSetupPage()

    }


    addJobRefreshTable() {
        this.refreshResponseDataKey = this.refreshResponseDataKey + 1
    }


    addToScheduleSetupPage() {
        var columns = this.addJobColumns()
        // @ts-ignore
        for (var x = 0; x < columns.length; x++) {
            // @ts-ignore
            if (columns[x].ColumnType == "WELL") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: {text: this.activeTasq!.wellName, value: this.activeTasq!.wellName}
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "USER") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: this.activeTasq!.username
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "VENDOR") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: {
                        "PhoneNumber": '',
                        "Email": '',
                        "FullName": ''
                    }
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "CHECKLIST") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "FILE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: null
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TESTERS") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TASQ_DATE_RANGE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "COST") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TAGS") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: []
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "TEXT") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "DATE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "START_DATE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "DUE_DATE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType == "PROCEDURE") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: null
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "EMAIL") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            // @ts-ignore
            } else if (columns[x].ColumnType  == "PHONE_NUMBER") {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            } else {
                // @ts-ignore
                this.addJobResponseData[columns[x].CustomID] = {
                    // @ts-ignore
                    ...columns[x],
                    Response: ''
                }
            }
        }
        this.refreshResponseDataKey = this.refreshResponseDataKey + 1
        
    }







  closeDialog() {
	  this.showScheduleModal = false
    this.showModal = false;
	this.popupBackground = false;
	
  }

   setCycleTimeMaxValue(search){
    if(search){
      this.maxNumCycleMax = 1
      this.cycleTimeValues =[search]
    }else {
      this.maxNumCycleMax =7
      this.cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70','80', '90']
    }
  }

  addToScheduleHideClicked() {
      this.closeDialog()
    this.popupBackground = false
	// @ts-ignore
    this.addToSchedulePopupInnerShowing = false
  }

   setDefermentMaxValue(search){
    if(search){
      this.maxNumDefermentMax = 1
      this.defermentUnitValues = [search]
    }else {
      this.maxNumDefermentMax =7
      this.defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700','800', '900']
    }
  }
  
  get jobTypeFilter() {
    return tasqsListModule.jobTypeFilter;
  }
  randomBackground() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }
  get completedTasqCount() {
    return this.tasqs.filter((i) => i.completed).length;
  }
  get notStartedCount() {
    return this.tasqs.length - this.activeTasqsCount - this.completedTasqCount;
  }
  get tasqsJobTypes() {
    return [...new Set(this.tasqs.map((i) => i.overriddenName))];
  }
  get usernames() {
    return tasqsListModule.usernamesList;
  }
  get tasqsJobTypesData() {

	var job_type_dict = {}
	for (var x = 0; x < this.tasqsJobTypes.length; x++) {
		job_type_dict[this.tasqsJobTypes[x]] = 0
	}
	for (var x = 0; x < this.tasqs.length; x++) {

		if (job_type_dict[this.tasqs[x].overriddenName] != null) {
			job_type_dict[this.tasqs[x].overriddenName] += 1
		}
		if (this.tasqs[x].overriddenName != "Scheduled Job" && this.tasqs[x].scheduledJobStatus == "Scheduled") {
			job_type_dict["Scheduled Job"] += 1
		}
		// if (this.tasqs[x].overriddenName )
	}

	var return_array: any[] = []
	for (var x = 0; x < this.tasqsJobTypes.length; x++) {
		
		return_array.push(job_type_dict[this.tasqsJobTypes[x]])
		// job_type_dict[] = 0
	}

	return return_array
    // return this.tasqsJobTypes.map(
    //   (i) => this.tasqs.filter((t) => t.overriddenName === i).length,
    // );
  }
  isScheduledTasq(tasq) {


     return (tasq.scheduledJobID != null && (tasq.scheduledJobStatus == '' || tasq.scheduledJobStatus == null || tasq.scheduledJobStatus == undefined || tasq.scheduledJobStatus.toLowerCase() == 'scheduled'))
  }
  isWaitingOnTasq(tasq) {

	if (tasq.scheduledJobID != null && tasq.scheduledJobStatus == 'Waiting On') {
		return true
	}

    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {

      // @ts-ignore
      if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {

		return false;
      }
      if (tasqsListModule.waitingTasqList[x].id == tasq.id) {

        return true;
      }
    }


    return false;
  }

    scheduledJobIsDone(tasq) {
        if (tasq.scheduledJobStatus != null && tasq.scheduledJobStatus == "Done") {
            return true
        }
        return false
    }

  selectUsername(users) {
    // this.dataLoading = true;
    tasqsListModule.setUsernamesBulkList(users);
    // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    localStorage.setItem('kanbanUsernamesFilter', JSON.stringify(tasqsListModule.usernamesList));
    // this.dataLoading = false;
    // this.setDraggableData();
  }
  getAcceptedSetpointTasqs(username) {
     const results: any[] = [];
    for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
      if (tasqsListModule.waitingTasqList[x].username.toLowerCase() != username.toLowerCase()) {
        continue;
      }
      // @ts-ignore
      if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted') && !tasqsListModule.waitingTasqList[x].completed) {
        results.push(tasqsListModule.waitingTasqList[x]);
      }
    }
    return results;
  }
  getTasqsInProgress(tasqs) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      if ((tasq.lastResponseTime.length || (tasq.scheduledJobStatus != null && tasq.scheduledJobStatus == "In Progress")) && !tasq.completed && !this.scheduledJobIsDone(tasq) && !tasq.completedForToday && !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }
      return results;
  }


  getWaitinOnTasqList(tasqs, username) {



	

      const results: any[] = [];

		for (var x = 0; x < tasqs.length; x++) {
			if (this.isWaitingOnTasq(tasqs[x])) {
				results.push(tasqs[x])
			}
		}
		for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
          if (tasqsListModule.waitingTasqList[x].username.toLowerCase() == username.toLowerCase()) {
                // @ts-ignore
                if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
                return false;
                }
                if (!tasqsListModule.waitingTasqList[x].completed) {
					var is_in = false
					for (var y = 0; y < results.length; y++) {
						if (results[y].id == tasqsListModule.waitingTasqList[x].id) {
							is_in = true
						}
					}
					if (!is_in) {
						results.push(tasqsListModule.waitingTasqList[x]);
					}
                	
                }
          }
		}

    //   for (let x = 0; x < tasqsListModule.waitingTasqList.length; x++) {
    //       if (tasqsListModule.waitingTasqList[x].username.toLowerCase() == username.toLowerCase()) {
    //             // @ts-ignore
    //             if (tasqsListModule.waitingTasqList[x].snoozed != null && tasqsListModule.waitingTasqList[x].snoozed.WaitUntilReason.includes('Setpoint accepted')) {
    //             return false;
    //             }
    //             if (!tasqsListModule.waitingTasqList[x].completed) {
    //             results.push(tasqsListModule.waitingTasqList[x]);
    //             }
    //       }
    //   }

    //   for (var x = 0; x < this.tasqs.length; x++) {

    //       if (this.tasqs[x].username.toLowerCase() == username.toLowerCase()) {
    //         // @ts-ignore
    //         if (this.tasqs[x].scheduledJobID != null && this.tasqs[x].scheduledJobStatus == 'Waiting On') {
    //             results.push(this.tasqs[x])
    //         }
    //       }

    //   }
      return results;
  }


  
  getScheduledTasqs(tasqs, email) {

    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];

      if (this.isScheduledTasq(tasq) && !tasq.completed && !this.scheduledJobIsDone(tasq) && !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }

      return results;
  }
  getNewTasqs(tasqs) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];
      if (!tasq.lastResponseTime.length && !tasq.completed && !this.scheduledJobIsDone(tasq) && (!this.isScheduledTasq(tasq) || (tasq.scheduledJobStatus != null && tasq.scheduledJobStatus == "New"))&& !this.isWaitingOnTasq(tasq)) {
        results.push(tasq);
      }
    }
      return results;
  }
  getResolvedTasqs(tasqs, username) {
    const results: any[] = [];
    for (let x = 0; x < tasqs.length; x++) {
      const tasq = tasqs[x];

      if (tasq.completed || tasq.completedForToday || this.scheduledJobIsDone(tasq)) {
        results.push(tasq);
      }
    }
    const setpointResults = this.getAcceptedSetpointTasqs(username.toLowerCase());
    results.push(...setpointResults);
      return results;
  }
  closeFilterDialog() {
    this.showFilterMenu = false;
  }
  async applyFiltering() {
    this.dataLoading = true;
    this.showFilterMenu = false;
    this.showFilterCycleTimeMenu = false;
    this.showFilterDefermentMenu = false;
    this.showFilterJobTypeMenu = false;
    this.showFilterUserMenu = false;
    this.showFilterTeamMenu = false;
    this.dataLoading = true;
    tasqsListModule.setCycleTimeLessThanFilter(this.cycleTimeLessThanFilter)
    tasqsListModule.setUnitsLessThanFilter(this.unitsLessThanFilter)
    tasqsListModule.setUnitsGreaterThanFilter(this.unitsGreaterThanFilter)
    tasqsListModule.setCycleTimeGreaterThanFilter(this.cycleTimeGreaterThanFilter)
    
    tasqsListModule.setActiveTeamFilter(this.selectedTeams);
    tasqsListModule.setActiveRouteFilter(this.selectedRoutes)
    tasqsListModule.setJobTypeFilter(this.typesOptions);
    this.sleep(100).then(async () => {
      this.selectUsername(this.selectedUsernames.map((u) => u.value));
      localStorage.setItem('kanbanCycleFilter', this.cycleTimeDays);
      localStorage.setItem('kanbanDefermentFilter', this.defermentDays);
      localStorage.setItem('kanbanJobTypeFilter', JSON.stringify(this.typesOptions));
      this.filterType = '';
      await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
      this.dataLoading = false;
      this.setDraggableData();
    });
  }
  async resetFiltering() {
    this.dataLoading = true;
    if (this.filterType === 'users') {
      this.selectedUsernames = [];
      this.selectUsername([]);
    } else if (this.filterType === 'jobType') {
      this.typesOptions = [];
      await this.applyFiltering();
    } else if (this.filterType === 'cycleTime') {
      this.cycleTimeGreaterThanFilter = null;
    this.cycleTimeLessThanFilter = null;
      await this.applyFiltering();
    } else if (this.filterType === 'deferment') {
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
      await this.applyFiltering();
    } else if (this.filterType === 'routes') {
      this.selectedRoutes = [];
       await this.applyFiltering();
    } else if (this.filterType === 'teams') {
      this.selectedTeams = [];
       await this.applyFiltering();
    }
  }
  reasignTasq(action: any) {
    tasqActionsModule.mutateTasqReassign({ comment: '', person: action.person, id: action.id });
  }



    get totalScheduledItems() {
        // @ts-ignore
        return workspaceModule.totalItems
    }


  setDraggableData() {



    // @ts-ignore
    this.draggableData = Object.entries(groupBy(this.tasqs, 'username')).map((i: any) => ({
      username: getNameByEmail(i[0].toLowerCase()),
      email: i[0],
      tasqs: i[1] as TasqJob[],
      new: this.getNewTasqs(i[1] as TasqJob[]),
      inProgress: this.getTasqsInProgress(i[1] as TasqJob[]), // i[1].filter(i => i.lastResponseTime.length && !i.completed),
      waitingOn: this.getWaitinOnTasqList(i[1] as TasqJob[], i[0]),
      scheduled: this.getScheduledTasqs(i[1] as TasqJob[], i[0]),
      resolved: this.getResolvedTasqs(i[1] as TasqJob[], i[0]),
    }));
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  get enableRoutes() {
    return scheduleModule.enabledRoutes.map((r) => r.Route);
  }
  async created() {
    tasqsListModule.setActivePage('Kanban');
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
    this.selectedRoutes = [];
    this.selectedTeams = [];
    this.cycleTimeGreaterThanFilter = null;
    this.cycleTimeLessThanFilter = null;
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
    this.dataLoading = true;
    workspaceModule.getWorkspaces(null)
    assetsModule.getEnabledWells();
    await this.getKanbanData();
    await scheduleModule.getEnabledRoutes();
    await workflowModule.listTeams();
    this.dataLoading = false;
  }
  async getKanbanData() {
      this.dataLoading = true;
    this.hideWellDetail = false;
    this.hideSignals = false;
    this.showModal = false;
	this.showScheduleModal = false
    await accountModule.getReassignmentList();
    // await tasqsListModule.getWaitList();
    const kanbanCycleFilter = localStorage.getItem('kanbanCycleFilter');
    const kanbanDefermentFilter = localStorage.getItem('kanbanDefermentFilter');
    const kanbanJobTypeFilter = localStorage.getItem('kanbanJobTypeFilter');
    let kanbanUsernamesFilter = localStorage.getItem('kanbanUsernamesFilter');
    if (kanbanCycleFilter) {
      tasqsListModule.setCycleTimeDaysFilter(kanbanCycleFilter);
      this.cycleTimeDays = tasqsListModule.cycleTimeDays;
    }
    if (kanbanDefermentFilter) {
      tasqsListModule.setDefermentDaysFilter(kanbanDefermentFilter);
      this.defermentDays = tasqsListModule.defermentDays;
    }
    if (kanbanJobTypeFilter) {
      tasqsListModule.setJobTypeFilter(JSON.parse(kanbanJobTypeFilter));
      this.typesOptions = tasqsListModule.jobTypeFilter;
    } else {
      this.typesOptions = [];
      tasqsListModule.setJobTypeFilter([]);
    }
    if (kanbanUsernamesFilter) {
      this.selectedUsernames = [];
      kanbanUsernamesFilter = JSON.parse(kanbanUsernamesFilter);
      this.availableUsers.forEach((i) => {
        if (kanbanUsernamesFilter?.includes(i.value)) {
          this.selectedUsernames.push(i);
        }
      });
      tasqsListModule.setUsernamesBulkList(kanbanUsernamesFilter);
    }
    
    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    await workspaceModule.getWorkspaces({})
    // @ts-ignore
    workspaceModule.setTotalItems()

    this.dataLoading = false;
    // @ts-ignore
    this.setDraggableData();
  }
  beforeDestroy() {
    tasqsListModule.setActivePage('');
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setKanbanSelectedTasq('');
  }
  setActionPerform() {
    this.isActionNotPerform = true;
  }
  @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if (id) {
      this.showModal = true;
    } else {
		this.showScheduleModal = false
      this.showModal = false;
      
      // this.setLoffding()
    }
  }
@Debounce(50)
  setLoading(){
     this.dataLoading = true;
 this.sleep(1500).then( () => {
          this.dataLoading = false;
        })
    
  }
   @Watch('activeTasq')
  async onactiveTasqChange(id: string) {
    if (id) {
      this.showModal = true;
      this.isActionNotPerform = false;
    } else {
      
      if (!this.isActionNotPerform) {
        this.sleep(3500).then(async () => {
			this.showScheduleModal = false
          this.showModal = false;
        // this.dataLoading = true;
          await this.getKanbanData();
          this.setDraggableData();
        });
      } else {
        this.setDraggableData();
      }
    }
  }
}
